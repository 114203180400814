<template>
  <img
    :src="logo"
    alt=""
  >
</template>

<script>
import darkLogo from '@/assets/images/logo/darkLogo.png';
import lightLogo from '@/assets/images/logo/logo.png';

export default {
    computed: {
        logo() {
            return this.$store.state.appConfig.layout.skin === 'dark' ? darkLogo : lightLogo;
        },
    },
};
</script>
