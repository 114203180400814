import BaseOsirisService from './BaseOsirisService';

class AuthService extends BaseOsirisService {
    async login({ email, password }) {
        try {
            const response = await this.api.post('/login', { email, password });
            this.api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
            return response;
        } catch ({ response }) {
            return response;
        }
    }

    async authenticate() {
        try {
            return await this.api.post('/authenticate', {});
        } catch ({ response }) {
            return response;
        }
    }

    async logout() {
        try {
            return await this.api.post('/logout', {});
        } catch ({ response }) {
            return response;
        }
    }

    async createPassword({ email, password, confirm_password }, token) {
        try {
            return await this.api.post(`/create-password/${token}`, { email, password, confirm_password });
        } catch ({ response }) {
            return response;
        }
    }

    async forgotPassword({ email }) {
        try {
            return await this.api.post('/forgot-password', { email });
        } catch ({ response }) {
            return response;
        }
    }

    async recoverPassword({ new_password, confirm_password }, token) {
        try {
            return await this.api.put(`/recover-password/${token}`, { new_password, confirm_password });
        } catch ({ response }) {
            return response;
        }
    }

    async resetPassword({ old_password, new_password, confirm_password }) {
        try {
            return await this.api.put('/reset-password', { old_password, new_password, confirm_password });
        } catch ({ response }) {
            return response;
        }
    }

    async validateToken(token) {
        try {
            return await this.api.get(`/validate-token/${token}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new AuthService();
